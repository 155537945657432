import 'react-toastify/dist/ReactToastify.css';

import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { MAX_WIDTH_TABLET } from '@/const';
import { Footer, Header } from '@/fsd/shared/ui';
import { Divider, useMediaQuery } from '@mui/material';

function App() {
  const isTablet = useMediaQuery(MAX_WIDTH_TABLET);

  return (
    <>
      {/* <Counter /> */}
      <Header />
      {!isTablet && <div style={{ paddingBottom: '0.5rem' }}></div>}
      {/* <Breadcrumbs /> */}
      <Outlet />
      <Divider />
      <Footer />
      <ToastContainer />
    </>
  );
}

export default App;
