import { useState } from 'react';

import { FilterIcon } from '@/assets/images/svgIcons/FilterIcon';
import { FiltersContainer } from '@/fsd/pages/ProductsPage/impl/_ulbi';
import { Box, Drawer } from '@mui/material';

export function MobileFilters() {
  const [hamburgerOpen, setHamburger] = useState<boolean>(false);

  return (
    <>
      <button onClick={() => setHamburger(!hamburgerOpen)}>
        <FilterIcon />
      </button>

      <Drawer open={hamburgerOpen} onClose={() => setHamburger(false)}>
        <Box sx={{ padding: '0 10px' }} role='presentation'>
          <FiltersContainer />
        </Box>
      </Drawer>
    </>
  );
}
