import { IFiltersRTKQ } from '@/fsd/entities/ProductFilters/model/types/filtersRTKQType';
import { AgeItemType } from '@/fsd/widgets/ProductsFilters/imlp/AgeFilter/types/AgeItemType';

import { ItemMenu } from './widgets/ItemMenu';
import { ItemMenuAndSubItems } from './widgets/ItemMenuAndSubItems';

interface RenderItemMenuProps {
  item: AgeItemType;
  requestedFiltersRTKQ: IFiltersRTKQ;
}

export function RenderItemMenu(props: RenderItemMenuProps) {
  const { requestedFiltersRTKQ, item } = props;

  const subItems = requestedFiltersRTKQ.categories.values.filter(
    (child) => child.parent_id === item.id
  );

  if (subItems.length === 0) {
    return <ItemMenu item={item} />;
  }

  return <ItemMenuAndSubItems item={item} subItems={subItems} />;
}
