import { Link } from 'react-router-dom';

import {
  AgeItemType,
  AgeItemTypeMenu,
} from '@/fsd/widgets/ProductsFilters/imlp/AgeFilter/types/AgeItemType';
import { CaretDownIcon } from '@radix-ui/react-icons';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';

interface ItemMenuAndSubItemsProps {
  item: AgeItemType;
  subItems: AgeItemType[];
}

/**
 * Елемент меню та його підменю
 * @param props
 * @returns
 */
export function ItemMenuAndSubItems(props: ItemMenuAndSubItemsProps) {
  const { item } = props;

  const _item = item as AgeItemTypeMenu;

  return (
    <>
      <NavigationMenu.Item>
        <Link
          to={_item.link}
          className='NavigationMenuLink'
          style={{ padding: 0 }}
        >
          <NavigationMenu.Trigger className='NavigationMenuTrigger'>
            {item.name}
            <CaretDownIcon className='CaretDown' aria-hidden />
          </NavigationMenu.Trigger>
        </Link>
        <_SubItems {...props} />
      </NavigationMenu.Item>
    </>
  );
}

const _SubItems = (props: ItemMenuAndSubItemsProps) => {
  const { subItems } = props;

  return (
    <NavigationMenu.Content className='NavigationMenuContent'>
      <ul className='List two'>
        {subItems.map((item, _idx) => {
          const _item = item as AgeItemTypeMenu;

          return (
            <li key={_idx}>
              <NavigationMenu.Item>
                <Link
                  to={'/?product_category=' + _item.id}
                  className='NavigationMenuLink'
                >
                  {_item.name}
                </Link>
              </NavigationMenu.Item>
            </li>
          );
        })}
      </ul>
    </NavigationMenu.Content>
  );
};
