import './css/header-top.scss';

import { Link } from 'react-router-dom';

import { MAX_WIDTH_TABLET } from '@/const';
import { useAppSelector } from '@/fsd/app/providers/ReduxStoreByOfficialDoc/hooks';
import { getItemsByCount } from '@/fsd/entities/cart';
import { useMediaQuery } from '@mui/material';

import { MenuMobile } from '../../HeaderBottom/impl';
import { MobileFilters } from '../widgets/MobileFilters';

export const HeaderTop = () => {
  const isTablet = useMediaQuery(MAX_WIDTH_TABLET);
  const productsByCount = useAppSelector(getItemsByCount);
  const sumQty = productsByCount.reduce((acc, _) => (acc += _.qty), 0);

  return (
    <section aria-label='Top header content' className='Header-Top'>
      <div className='ContentWrapper '>
        <div className='Header-Wrapper'>
          <div className='Header-StoresWrapper'>
            <div style={{ display: 'flex', gap: '24px' }}>
              {/* <a href='/store-locator'>Магазини</a> */}
              {isTablet && <MenuMobile />}
              {isTablet && <MobileFilters />}
            </div>
          </div>
          <div className='Header-SaleLink'>
            БЕЗКОШТОВНА ДОСТАВКА ПРИ ЗАМОВЛЕННІ ВІД 2500₴
          </div>
          <div className='Header-AccountLinksWrapper'>
            <Link to={`/cart`}>
              <div
                className='MiniCart MiniCart_empty'
                style={{
                  position: 'relative',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                <span>Кошик</span>
                <div className='MiniCart-Icon' style={{ position: 'relative' }}>
                  <svg>
                    <use href='images/icons/mini-cart.svg#icon'></use>
                  </svg>
                  <div aria-label='Minicart Badge' className='MiniCart-Badge'>
                    {sumQty}
                  </div>
                </div>
              </div>
            </Link>
            {/* <span style={{ width: '20px' }}></span>
            <Link to={`/cart-old`}>
              <span> Кошик (old)</span>
            </Link> */}
            {/* <LangSwitcher /> */}
          </div>
        </div>
      </div>
    </section>
  );
};
